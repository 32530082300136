html, body {
  overflow-x: hidden;
  min-height: 100%!important;
}

html {
  height: 100%;
}

.App {
  text-align: center;
  background-image: url("./background.jpg");
  min-height: 100vh;
}

.navlinks {
  padding-left:30px;
}

.navlinks:hover {
  text-decoration: none;
  color:#ffffff !important;

}

.navbar-text {
  color:#9840d0 !important;
  font-size: 17px;
  font-weight: 500;
  -webkit-transition: color 0.75s; /* For Safari 3.0 to 6.0 */
  transition: color 0.75s; /* For modern browsers */
}

.navbar-brand {
  color:#FC1B27 !important;
  font-size: 22px !important
  ;
  font-weight: 600;
  -webkit-text-stroke: 0.1px black;
}

.padded-main-content {
  padding-top: 10vh;
}

.main-text {
  color:#3b3b3b;
  margin-left: 5vw;
  font-size: 15px;
}

.header-img {
  padding-top: 10%;
  width:70%;
}

.home-banner {
  color:#FF4D4F;
  font-weight:700;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-text-stroke: 0.75px black;
}

.home-banner-subtext {
  font-weight: 600;
  font-size: 24px;
}

.svg-sub-text {
  padding-left: 2vw;
}

.skills-div {
  padding-top:10vh;
}

.skills-logo {
  padding-right:10px;
}

.skills-text {
  display:inline-block;
  padding-left:10px;
  color: #636363;
  font-size:25px;
}

#skill-header {
  color: #4d4d4d;
  font-size: 10vh;
  font-weight: 400;
}

.skills-title {
  color:#4d4d4d;
  font-size:4vh;
}

.social-icon {
  margin:3vh;
  
}

.projects-title-text {
  color:#FF4D4F;
  font-size: 36px;
  padding-left: 10vw;
}

.card-img {
  height:10vw;
  object-fit: contain;
}

.card-col {
 padding: 0!important;
 max-width: 450px !important;
}

.project-card {
  background-color: rgb(248, 248, 248) !important;
  color:black !important;
  border-color: #1A1A1D !important;
  border-radius: 4px;
}

.proj-git-link {
  color:#9840d0 !important;
  font-size: 36px;
  -webkit-transition: color 0.75s; /* For Safari 3.0 to 6.0 */
  transition: color 0.75s; /* For modern browsers */
}


.proj-git-link:hover {
  color: #fafafa !important;
}

.contact-form-sect {
  display: block;
  margin: 0 auto;
  padding: 0 0 10px 10px;
  max-width: 650px;
  min-width: 300px;
  width:30vw;
}

.contact-form {
  padding: 10px 10px 0 0;
}

.cf-textarea {
  margin: 5px 0;
	width: 100%;
	height: 100px;
}

.form-labels {
  color: rgb(0, 0, 0);
  margin: 0;
}

.wrapper {
  height:90vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.contact-sub {
  width: 50%;
  height: 50px;
  background-color: #FF4D4F;
  border: 0;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  margin: 10px 0 0 0;
  font-size: 18px;
  -webkit-transition: border 0.75s!important; /* For Safari 3.0 to 6.0 */
  transition: border 0.75s!important /* For modern browsers */
}

.contact-sub:hover {
  border: 1px solid rgba(50, 50, 56, 0.404);
}

.cf-title {
  display: inline-block;
}

.cf-title h2 {
  padding: 0 10px 0 0px;
	margin: 5px 0;
}

.loop-items {
  color:#9840d0;
  -webkit-text-stroke: 0.1px black;
}

.social-icons .social-svg {
  color: #FF4D4F;
  -webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
  transition: color 0.5s; /* For modern browsers */
}

.social-icons .social-svg:hover{
  color: #ff6e71;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .home-banner {
    font-size: 70px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .home-banner {
    font-size: 40px;
  }
}

hr {
  border-style: solid;
  border-color: black !important;
  margin-left:0;
  margin-right:auto;
}

.experience {
  width:50vh ;
}

.exp-hr {
  width:100%;
  border-style:solid;
  border-color:#1A1A1D !important;
  margin-top: 0;
  margin-bottom: 0;
}

.experience-card {
  border-radius: 2px;
  margin:5px;
}

.exp-title {
  color: #4d4d4d;
  font-size:1.2em;
}

.exp-maintext {
  color: #636363;
  font-size: 1.2em;
}

.exp-img {
  padding:5vh;
}

.skills-wrapper {
  min-width: 200px;
}

.skill-container {
  padding-left: 4vw;
}


.background-div {
  border-radius: 3px;
  background: whitesmoke;
  background-image: url("./assets/spikes.png");
}

@media only screen and (min-width: 600px) {
  .background-div {

    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom:5vw;
  }
}

@media only screen and (max-width: 600px) {
  .background-div {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

.box {
  position: relative;
  padding-right: 10px;
  color:white;
  font-weight: 400;
  opacity: .5;
}

.footCopy {
  position: absolute;
  right: 0;
  bottom:0;

}

.ant-card-hoverable :hover{
  opacity:1!important;
  background-color: #424242!important;
}

.test-img {
  height:100%;
  width:100%;
}


.wrapper-ex {
  min-width: 300px;
  max-width: 450px;

}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #424242;
}

.wrapper-ex:hover .overlay {
  opacity: 0.9;
}

.example-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  text-decoration: none !important;
}

.example-text:hover {
  color: #ff6e71;
}

.service-icon {
  max-height: 80px;
}

.heading-container {
  min-height: 75px;
  max-height: 100px;
}

.heading-container h2 {
  font-size: 1.5rem;
  padding-top: 20px;
  font-weight: 600;
}

.body-container {
  min-height: 100px;
  max-height: 150px;
  color:#1f1f1f;
  font-weight: 500;
  font-size: 1.1rem;
}

.service-container {
  min-width: 300px;
}